<template>
  <b-card>
    <b-overlay :show="show" rounded="sm">
      <b-form @submit.prevent>
        <b-row>
          <b-col md="7">
            <b-row>
              <b-col md="2" class="m-0 p-0">
                <b-form-input type="text" size="sm" v-model="_search.product.product_id" placeholder="Ürün No"/>

                <vue-autosuggest
                    :suggestions="filteredBrands"
                    :limit="limit"
                    :input-props="{id:'autosuggest__input',class:'form-control form-control-sm', placeholder:'Marka Seçiniz!'}"
                    @input="onInputChangeBrand"
                    @selected="selectHandlerBrand"
                >
                  <template slot-scope="{suggestion}">
                    <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                  </template>
                </vue-autosuggest>

                <b-badge v-for="(brand,key) in _search.brands" :key="brand.id"
                         @click="deleteBrand(key)">{{ brand.name }}
                </b-badge>

              </b-col>
              <b-col md="2" class="m-0 p-0">
                <b-form-input type="text" size="sm" v-model="_search.product.name" placeholder="Ürün Adı"/>

                <v-select
                    v-model="_search.category_ids"
                    multiple
                    label="name"
                    :reduce="category => category.id"
                    :options="fiterCategory"
                    class="select-size-sm"
                    :filterable="false"
                    @search="onSearchCategory"
                    @open="getAllCategory"
                    placeholder="Kategori"
                />
              </b-col>
              <b-col md="2" class="m-0 p-0">
                <b-form-input type="text" size="sm" v-model="_search.product.barcode" placeholder="Ürün Barkod"/>
                <v-select
                    v-model="_search.publisher_ids"
                    multiple
                    label="publisher_name"
                    :reduce="publisher => publisher.id"
                    :options="fiterPublisher"
                    class="select-size-sm"
                    :filterable="false"
                    @search="onSearchPublisher"
                    @open="getAllPublisher"
                    placeholder="Yayımevleri"
                />
              </b-col>
              <b-col md="2" class="m-0 p-0">
                <b-form-input type="text" size="sm" v-model="_search.id" placeholder="Ted. Ürün No"/>
                <v-select
                    v-model="_search.author_ids"
                    multiple
                    label="author_name"
                    :reduce="author => author.id"
                    :options="fiterAuthors"
                    class="select-size-sm"
                    :filterable="false"
                    @search="onSearchAuthors"
                    @open="getAllAuthors"
                    placeholder="Yazarlar"
                />
              </b-col>
              <b-col md="2" class="m-0 p-0">
                <b-form-input type="text" size="sm" v-model="_search.name" placeholder="Ted. Ürün Adı"/>

                <v-select
                    v-model="_search.status"
                    label="status"
                    multiple
                    :options="_matchStatus"
                    :reduce="statu => statu.id"
                    class="select-size-sm"
                    placeholder="Durum"
                />
              </b-col>
              <b-col md="2" class="m-0 p-0">
                <b-form-input type="number" size="sm" v-model="_search.barcode" placeholder="Ted. Ürün Barkod"/>
                <v-select
                    v-model="_search.supplier_ids"
                    label="name"
                    multiple
                    :options="_suppliers"
                    :reduce="item => item.id"
                    class="select-size-sm"
                    placeholder="Tedarikçi"
                    :closeOnSelect="false"
                >
                </v-select>

              </b-col>
            </b-row>
          </b-col>

          <b-col md="2" class="m-0 p-0">
            <b-form-group>
              <b-input-group>
                <b-input-group-prepend>
                  <b-form-select
                      size="sm"
                      v-model="_search.stock.condition"
                      :options="stockCondition"
                  />
                </b-input-group-prepend>
                <b-form-input
                    :class="quantityXStatus"
                    type="number"
                    size="sm"
                    placeholder="Stok Miktar"
                    v-model="_search.stock.quantityX"
                >
                </b-form-input>
                <b-form-input
                    :class="quantityYStatus"
                    v-if="_search.stock.condition=='><'"
                    type="number"
                    size="sm"
                    placeholder="Stok Miktar"
                    v-model="_search.stock.quantityY"
                >
                </b-form-input>

              </b-input-group>
            </b-form-group>
          </b-col>


          <b-col md="3">
            <b-row>
              <b-col md="5" class="m-0 p-0">
                <v-select v-model="_search.limit" :options="[1,5,10,20,50,100,250,500]" class="select-size-sm"/>
              </b-col>
              <b-col md="7" class="p-0">
                <b-button-group size="sm">
                  <b-button variant="primary" type="submit" @click="getAll(1)" title="Getir">
                    <feather-icon icon="FilterIcon"></feather-icon>
                  </b-button>

                  <b-button variant="danger" @click="clearSearch" title="Temizle">
                    <feather-icon icon="XCircleIcon"></feather-icon>
                  </b-button>

                  <b-dropdown size="sm" text="Seçenek" variant="outline-dark">
                    <b-form-checkbox-group
                        v-model="$store.state.supplierProduct.search.selectedOptions"
                        :options="$store.state.supplierProduct.optionsView"
                        switches
                        class="demo-inline-spacing"
                    />
                  </b-dropdown>
                  <b-dropdown size="sm" text="Tercih" variant="outline-dark">
                    <b-form-checkbox-group
                        v-model="$store.state.supplierProduct.search.selectedFilter"
                        :options="_filterView"
                        switches
                        class="demo-inline-spacing"
                    />
                  </b-dropdown>
                </b-button-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="demo-inline-spacing" style="margin-top: 0px">
                  <b-form-radio
                      class="custom-control-info"
                      style="margin-top: 0px"
                      v-model="_search.isSupplierProductChange"
                      plain
                      name="isSupplierProductChange"
                      value="all"
                  > Tümü
                  </b-form-radio>
                  <b-form-radio
                      class="custom-control-info"
                      style="margin-top: 0px"
                      v-model="_search.isSupplierProductChange"
                      plain
                      name="isSupplierProductChange"
                      value="in"
                  > Değişmiş
                  </b-form-radio>
                  <b-form-radio
                      class="custom-control-info"
                      style="margin-top: 0px"
                      v-model="_search.isSupplierProductChange"
                      plain
                      name="isSupplierProductChange"
                      value="out"
                  > Değişmemiş
                  </b-form-radio>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
      <Pagination :pagination="_pagination" @pageClick="getAll"/>
    </b-overlay>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import {mapGetters} from "vuex";
import Vue2Filters from "vue2-filters";
import Pagination from "@/components/Pagination";
import {VueAutosuggest} from 'vue-autosuggest'

export default {
  name: "Search",
  components: {vSelect, Pagination, VueAutosuggest},
  mixins: [Vue2Filters.mixin],
  data: () => ({
    searchPublisher: "",
    searchCategory: "",
    searchAuthor: "",
    show: false,
    filteredBrands: [],
    limit: 20,
  }),
  computed: {
    ...mapGetters("supplierProduct", ["_search", "_filterView", "_pagination"]),
    ...mapGetters("supplierCategory", ["_categories"]),
    ...mapGetters("suplierPublisher", ["_publishers"]),
    ...mapGetters("supplierAuthors", ["_authors"]),
    ...mapGetters('matchStatus', ["_matchStatus"]),
    ...mapGetters("productBrand", ["_brands"]),
    ...mapGetters("supplier", ["_suppliers"]),
    stockCondition() {
      return [
        {value: "", text: ""},
        {value: ">", text: "Büyük"},
        {value: "=", text: "Eşit"},
        {value: "<", text: "Küçük"},
        {value: "><", text: "Arada"},
      ]
    },
    quantityXStatus() {
      if (this._search.stock.condition == '') return '';
      let num1 = parseInt(this._search.stock.quantityX);
      if (num1 || num1 == 0) return 'border-success'
      return 'border-danger'
    },
    quantityYStatus() {
      let num1 = parseInt(this._search.stock.quantityX);
      let num2 = parseInt(this._search.stock.quantityY);
      if (num1 && num2 && num1 < num2) return 'border-success'
      return 'border-danger'
    },
    fiterPublisher() {
      let data = this._publishers.filter(publisher =>
          publisher.publisher_name.toLocaleLowerCase().includes(this.searchPublisher.toLocaleLowerCase())
      );
      return data.splice(0, 30);
    },
    fiterCategory() {
      let data = this._categories.filter(category =>
          category.name.toLocaleLowerCase().includes(this.searchCategory.toLocaleLowerCase())
      );
      return data.splice(0, 30);
    },
    fiterAuthors() {
      let data = this._authors.filter(item => item.author_name.toLocaleLowerCase().includes(this.searchAuthor.toLocaleLowerCase()));
      return this.limitBy(data, 10)
    }
  },
  created() {
    this.$store.dispatch('productBrand/getAll')
  },
  methods: {
    getAll(page) {
      this.show = true;
      this._search.page = page;
      this.$store.dispatch("supplierProduct/getAll").then(res => {
        if (res) this.show = false;
      })
    },
    clearSearch() {
      alert()
    },
    getAllCategory() {
      if (this._categories.length < 1) {
        this.$store.dispatch("supplierCategory/all");
      }
    },
    getAllPublisher() {
      if (this._publishers.length < 1) {
        this.$store.dispatch("suplierPublisher/all");
      }
    },
    getAllAuthors() {
      if (this._authors.length < 1) {
        this.$store.dispatch("supplierAuthors/all");
      }
    },
    onSearchPublisher(query) {
      this.searchPublisher = query;
    },
    onSearchCategory(query) {
      this.searchCategory = query;
    },
    onSearchAuthors(query) {
      this.searchAuthor = query
    },
    onInputChangeBrand(text) {
      if (text === '' || text === undefined) return
      const data = this._brands.filter(brand => brand.name.toLowerCase().indexOf(text.toLowerCase()) > -1).splice(0, this.limit);
      this.filteredBrands = [{data}]
    },
    selectHandlerBrand(option) {
      this.$store.state.supplierProduct.search.brands.push(option.item);
    },
    deleteBrand(key) {
      this.$store.commit('supplierProduct/deleteBrand', key)
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';

</style>
