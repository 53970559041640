<template>
  <table class="table table-hover table-sm">
    <thead>
    <tr>
      <th>Tadarikçi</th>
      <th>T.Resim</th>
      <th v-if="_search.selectedFilter.includes('product')">Ü.Resim</th>
      <th>Bilgi</th>
      <th v-if="_search.selectedFilter.includes('publishers')">Yayımevi</th>
      <th v-if="_search.selectedFilter.includes('categories')">Kategori</th>
      <th v-if="_search.selectedFilter.includes('authors')">Yazar</th>
      <th>Yönet</th>
    </tr>
    </thead>
    <tbody>
    <Product v-for="product in _supplierProducts" :key="product.id" :product="product"></Product>
    </tbody>
  </table>
</template>

<script>
import Product from "@/views/supplier-product/Product";
import {mapGetters} from "vuex";

export default {
  name: "SupplierProducts",
  components: {Product},
  computed: {
    ...mapGetters("supplierProduct", ["_supplierProducts", "_search"]),
  }
}
</script>

<style scoped>

</style>
