<template>
  <section>
    <b-card>
      <b-card-body>
        <Search></Search>
      </b-card-body>
    </b-card>


    <b-card>
      <div class="table-responsive">
        <SupplierProducts></SupplierProducts>
      </div>
    </b-card>


    <!--<EditStore></EditStore>-->
  </section>
</template>

<script>
import Search from "@/views/supplier-product/Search";
import SupplierProducts from "@/views/supplier-product/Products";
import {BRow, BCol, BCard, BCardBody} from "bootstrap-vue";

export default {
  name: "SupplierProductHome",
  components: {Search, SupplierProducts, BRow, BCol, BCard, BCardBody}
}
</script>

<style scoped>
.card-body {
  padding: 0px;
}
</style>
